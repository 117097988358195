import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [
	{
		path: '/:code/:sign',
		component: () => import('@/views/homes/IndexView.vue'),
		meta: {
			title: '首页'
		}
	},
	{
		path: '/index/:code/:sign?',
		// name: 'home',
		// redirect: '/articles',
		component: () => import('@/views/homes/IndexView.vue'),
		meta: {
			title: '首页'
		}
	}, {
		path: '/form',
		component: () => import('@/views/homes/FormView.vue'),
		meta: {
			title: '表单填写'
		}
	}, {
		path: '/formyzm',
		component: () => import('@/views/homes/FormYzm.vue'),
		meta: {
			title: '表单填写'
		}
	}, {
		path: '/pay',
		component: () => import('@/views/homes/PayView.vue'),
		meta: {
			title: '去支付'
		}
	}, {
		path: '/order',
		component: () => import('@/views/homes/OrderView.vue'),
		meta: {
			title: '订单信息'
		}
	}, {
		path: '/hetong',
		component: () => import('@/views/homes/HetongView.vue'),
		meta: {
			title: '订单信息'
		}
	},
	{
		path: '/login',
		component: () => import('@/views/homes/LoginView.vue'),
		meta: {
			title: '授权登录'
		}
	}
	
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	// 百度统计 s
	if (window._hmt) {
		if (to.path) {
			window._hmt.push(['_trackPageview', to.fullPath])
		}
	}
	// 百度统计 e
	next()
})


export default router