/* *
 * 数组封装文件
 */

export default {
	/* 严格校准 类型
	 * 判断一个元素是否在数组中
	*/
	hiIndexOf(arr, val) {
		return arr.indexOf(val) != -1 ? true : false
	},
	
	/* 不区分类型
	 * 判断一个元素是否在数组中 
	 */
	hiFindIndex(arr, val) {
		return arr.findIndex((e) => (e==val)) != -1 ? true : false
	},
	
	/* 区分大小写
	 * 判断数组内是否包含某个元素
	 * 返回 true
	 */
	hiInArray(arr, e) {
		return arr.includes(e)
	},

	/* 
	 * 判断数据类型 
	 */
	toType(data) {
		return Object.prototype.toString.call(data)
	},

	/**
	 * @param  {arr} 数组
	 * @param  {fn} 回调函数
	 * @return {undefined}
	 */
	each(arr, fn) {
		fn = fn || Function
		var a = []
		var args = Array.prototype.slice.call(arguments, 1)
		for (var i = 0; i < arr.length; i++) {
			var res = fn.apply(arr, [arr[i], i].concat(args))
			if (res != null) a.push(res)
		}
	},

	/**
	 * @param  {arr} 数组
	 * @param  {fn} 回调函数
	 * @param  {thisObj} this指向
	 * @return {Array} 
	 */
	map(arr, fn, thisObj) {
		var scope = thisObj || window
		var a = []
		for (var i = 0, j = arr.length; i < j; ++i) {
			var res = fn.call(scope, arr[i], i, this)
			if (res != null) a.push(res)
		}
		return a
	},


	/**
	 * @param  {arr} 数组
	 * @param  {type} 1：从小到大   2：从大到小   3：随机
	 * @return {Array}
	 */
	sort(arr, type = 1) {
		return arr.sort((a, b) => {
			switch (type) {
				case 1:
					return a - b
				case 2:
					return b - a
				case 3:
					return Math.random() - 0.5
				default:
					return arr
			}
		})
	},
	

	/*去重*/
	unique(arr) {
		// .new Set方法，返回是一个类数组，需要结合 Array.from ，转成真实数组
		return ( Array.from(new Set(arr)) )
	},

	/*求两个集合的并集*/
	bingji(a, b) {
		var newArr = a.concat(b)
		return this.unique(newArr)
	},

	// 对象求并集
	bingjiObj(a, b) {
		var arr1 = { ...a }
		var arr2 = { ...b }

		for (let i in arr2) {
			arr1[i] = arr2[i]
		}
		return arr1
	},

	/*求两个集合的交集*/
	jiaoji(a, b) {
		var _this = this
		a = this.unique(a)
		return this.map(a, function(o) {
			return _this.contains(b, o) ? o : null
		})
	},
	
	// 差集
	chaji (a, b) {
		return a.filter(item=>b.has(item)==false)
	},

	
	/*删除其中一个元素*/
	remove(arr, ele) {
		var index = arr.indexOf(ele)
		if (index > -1) {
			arr.splice(index, 1)
		}
		return arr
	},

	/*将类数组转换为数组的方法*/
	formArray(ary) {
		var arr = []
		if (Array.isArray(ary)) {
			arr = ary
		} else {
			arr = Array.prototype.slice.call(ary)
		}
		return arr
	},

	// 将对象 转化为数组 格式
	objToArray (obj) {
		let arr = []
		for (let i in obj) {
			arr[i] = obj[i]
		}
		return arr
	},

	/*最大值*/
	max(arr) {
		return Math.max.apply(null, arr)
	},

	/*最小值*/
	min(arr) {
		return Math.min.apply(null, arr)
	},

	/*求和*/
	sum(arr) {
		return arr.reduce((pre, cur) => {
			return pre + cur
		})
	},

	/*平均值*/
	average(arr) {
		return this.sum(arr) / arr.length
	},

	/**
	 * 数组去除子元素 双引号
	 */
	arrDelYh(arr) {
		if (arr == '') {
			return []
		}
		var a = arr.map(function(item) {
			return item - 0
		})
		return a
	},

	/**
	 * 保留下标 去除数组中某些值  组成新数组
	 * @param {*} arr 原始数组
	 * @param {*} rm 要去除的元素组成的数组
	 */
	arrRemove(arr, rm) {
		var res = []
		arr.filter(function(it, k) {
			if (it !== undefined && rm.indexOf(it) < 0) {
				res[k] = it
			}
		})
		return res
	},

	/**
	 * 不保留下标 去除数组中某些值  组成新数组
	 * @param {*} a 原始数组
	 * @param {*} b  条件数组
	 */
	arrRemove2(a, b) {
		let c = a.filter(item => !b.includes(item))
		return c
	},

	/**
	 * 根据下标 移除对象的值
	 */
	objKeyDel(obj, ks) {
		var i = ks.length;
		while (i--) {
			delete obj[ks[i]]
		}
		return obj
	},

	/**
	 * 返回二维数组 值  的 下标
	 * @param {*} arr 原数组
	 * @param {*} val 查询的值 
	 */
	arrIndex(arr, val) {
		var i = arr.length
		while (i--) {
			if (arr[i] != null && arr[i] != '' && arr[i]['id'] === Number(val)) {
				return i
			}
		}
		return -1
	},
	
	/* 
	 * 获取二维数组 字段值
	 */
	arrValue(arr, val, filed='num') {
		var i = arr.length
		while (i--) {
			if (arr[i] != null && arr[i] != '' && arr[i]['id'] === Number(val)) {
				return arr[i][filed]
			}
		}
		return 0
	},

	/**
	 * 返回二维数组 符合条件的 对象数组
	 * @param {*} arr   原数组
	 * @param {*} name   筛选的字段名称
	 * @param {*} pn   条件值数组
	 */
	arrObj(arr, name, pn) {
		var i = arr.length
		var list = []
		while (i--) {
			var it = arr[i][name]
			// console.log(pn.indexOf(it))
			if (pn.indexOf(it) > -1) {
				list.unshift(arr[i])
			}
		}
		return list
	},

	/**
	 * 二维数组转化为一维数组
	 * arr：原数组
	 * val：返回字段
	 */
	arrYi(arr, val) {
		var i = arr.length
		var list = []
		while (i--) {
			list.unshift(arr[i][val])
		}
		return list
	},
	
	/* *
	 * 二维转 一维 
	 * arr  原数组
	 * val  返回值字段
	 * key  下标字段
	 */
	arrYiKey (arr, val, key) {
		var i = arr.length
		var list = {}
		while (i--) {
			list[arr[i][key]] = arr[i][val]
		}
		return list
	}

}
