<template>

	<div v-if="!heimingdan_ip_state">
		<!-- 主体 s -->
		<main>
			<!-- 导航展示内容 -->
			<router-view />
		</main>
		<!-- 主体 e -->
		
		<!-- 尾巴 s -->
		<footer class="app-footer">
			<AppFooter :item="configs"></AppFooter>
		</footer>
		<!-- 尾巴 e -->
		
	</div>
	<!-- 404 页面 -->
	<div v-else class="my-404-box">
		<!-- <img src="@/assets/imgs/404.jpg" alt="" /> -->
		<div style="width: 100%;height: 5rem;"></div>
		<div class="my-404-code">404</div>
		<div class="my-404-txt">页面飞走了，重新打开链接试试！</div>
	</div>

</template>

<script>
	// import AppHeader from '@/components/AppHeader.vue'
	import AppFooter from '@/components/AppFooter.vue'

	export default {
		name: 'App',
		components: {
			// AppHeader,
			AppFooter,
		},
		data() {
			return {
				configs: [],
				heimingdan_ip_state: false
			}
		},
		created() {
			// 黑名单
			this.heimingdan_ip_state = this.$hi.getHeimingdanIp()
			if (!this.heimingdan_ip_state) {
				this.getConfig(2)
			}
		},
		methods: {
			// 配置信息
			getConfig(navId) {
				var that = this
				that.$api.any('configs', {
					nav_id: navId,
				}).then(res => {
					that.configs = Object.assign(that.configs, res)
					that.$hi.setCache('configs', res)
				})
			},
		}
	}
</script>